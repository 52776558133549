import { Grid, Collapse, Text, ThemeProvider, Card } from "@nextui-org/react"
import { VictoryChart, VictoryLine, VictoryVoronoiContainer, VictoryTooltip, VictoryLabel, VictoryAxis } from 'victory'
import {useMediaPredicate} from 'react-media-hook'
import config from '../config.json'

export default function Champion() {
    const biggerThan500 = useMediaPredicate("(min-width: 500px)");
    var calcLChamps = [2, 3, 6, 7, 9, 18, 25, 27, 54, 73, 97, 129, 171, 231, 313, 327, 649, 703, 871, 1161, 2223, 2463, 2919, 3711, 6171, 10971, 13255, 17647, 23529, 26623, 34239, 35655, 52527, 77031, 106239, 142587, 156159, 216367, 230631, 410011, 511935, 626331, 837799, 1117065, 1501353, 1723519, 2298025, 3064033, 3542887, 3732423, 5649499, 6649279, 8400511, 11200681, 14934241, 15733191, 31466382, 36791535, 63728127]
    var calcHChamps = [2, 3, 7, 15, 27, 255, 447, 639, 703, 1819, 4255, 4591, 9663, 20895, 26623, 31911, 60975, 77671, 113383, 138367, 159487, 270271, 665215, 704511, 1042431, 1212415, 1441407, 1875711, 1988859, 2643183, 2684647, 3041127, 3873535, 4637979, 5656191, 6416623, 6631675, 19638399, 38595583, 80049391]
    return (
        <div>
            <Collapse shadow title="H-Champions" style={{
                backgroundColor: config.DARK_ACCENT_COLOR,
                color: "white",
                marginTop: "20px",
                marginBottom: "30px"
            }}>
                <Grid.Container gap={2}>
                    <Grid>
                    {biggerThan500 && <Collapse.Group shadow style={{
                            width: "76vw",
                            backgroundColor: config.ACCENT_COLOR
                        }} divider>
                            {calcHChamps.map(book => (
                                <Collapse title={book}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <Card shadow={false} style={{
                                            height: "fit-content",
                                            width: "fit-content"
                                        }}>
                                            <div style={{
                                                display: "block",
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The length is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {CalcCollatz(book).length}
                                                    </Card>
                                                </div>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The maximum is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {Math.max(...CalcCollatz(book))}
                                                    </Card>
                                                </div>
                                            </div>
                                        </Card>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            float: "right"
                                        }}>
                                            <Card shadow={false} style={{
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "fit-content",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}>
                                                    <div>
                                                        {Chart(CalcCollatz(book))}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Collapse>
                            ))}
                        </Collapse.Group>}
                        {!biggerThan500 && <Collapse.Group shadow style={{
                            width: "68vw",
                            backgroundColor: config.ACCENT_COLOR
                        }} divider>
                            {calcHChamps.map(book => (
                                <Collapse title={book}>
                                    <div style={{
                                        display: "block"
                                    }}>
                                        <Card shadow={false} style={{
                                            height: "fit-content",
                                            width: "100%",
                                            marginBottom: "10px"
                                        }}>
                                            <div style={{
                                                display: "block",
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The length is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {CalcCollatz(book).length}
                                                    </Card>
                                                </div>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The maximum is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {Math.max(...CalcCollatz(book))}
                                                    </Card>
                                                </div>
                                            </div>
                                        </Card>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            float: "right"
                                        }}>
                                            <Card shadow={false} style={{
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "fit-content",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginBottom: "10px"
                                                }}>
                                                    <div>
                                                        {Chart(CalcCollatz(book))}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Collapse>
                            ))}
                        </Collapse.Group>}
                    </Grid>
                </Grid.Container>
            </Collapse>
            {biggerThan500 && <Collapse shadow title="L-Champions" style={{
                backgroundColor: config.DARK_ACCENT_COLOR,
                color: "white",
                marginBottom: "20px"
            }}>
                <Grid.Container gap={2}>
                    <Grid>
                        <Collapse.Group shadow style={{
                            width: "76vw",
                            backgroundColor: config.ACCENT_COLOR
                        }} divider>
                            {calcLChamps.map(book => (
                                <Collapse title={book}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <Card shadow={false} style={{
                                            height: "fit-content",
                                            width: "fit-content"
                                        }}>
                                            <div style={{
                                                display: "block",
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The length is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {CalcCollatz(book).length}
                                                    </Card>
                                                </div>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The maximum is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {Math.max(...CalcCollatz(book))}
                                                    </Card>
                                                </div>
                                            </div>
                                        </Card>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            float: "right"
                                        }}>
                                            <Card shadow={false} style={{
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "fit-content",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}>
                                                    <div>
                                                        {Chart(CalcCollatz(book))}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Collapse>
                            ))}
                        </Collapse.Group>
                    </Grid>
                </Grid.Container>
            </Collapse>}
            {!biggerThan500 && <Collapse shadow title="L-Champions" style={{
                backgroundColor: config.DARK_ACCENT_COLOR,
                color: "white",
                marginBottom: "20px"
            }}>
                <Grid.Container gap={2}>
                    <Grid>
                        <Collapse.Group shadow style={{
                            width: "68vw",
                            backgroundColor: config.ACCENT_COLOR
                        }} divider>
                            {calcLChamps.map(book => (
                                <Collapse title={book}>
                                    <div style={{
                                        display: "block"
                                    }}>
                                        <Card shadow={false} style={{
                                            height: "fit-content",
                                            width: "100%",
                                            marginBottom: "10px"
                                        }}>
                                            <div style={{
                                                display: "block",
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The length is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {CalcCollatz(book).length}
                                                    </Card>
                                                </div>
                                                <div style={{
                                                    color: "black"
                                                }}>
                                                    <h5>The maximum is:</h5><br />
                                                    <Card shadow={false} style={{
                                                        backgroundColor: config.BG_COLOR,
                                                        color: "white",
                                                        width: "fit-content"
                                                    }}>
                                                        {Math.max(...CalcCollatz(book))}
                                                    </Card>
                                                </div>
                                            </div>
                                        </Card>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            float: "right",
                                            marginBottom: "10px"
                                        }}>
                                            <Card shadow={false} style={{
                                                width: "fit-content"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "fit-content",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}>
                                                    <div>
                                                        {Chart(CalcCollatz(book))}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Collapse>
                            ))}
                        </Collapse.Group>
                    </Grid>
                </Grid.Container>
            </Collapse>}
        </div>
    )
}

function CalcCollatz(number) {
    let n = number
    let arr = [];
    n = n - 1
    n = n + 1
    //Pushes the inital Inputfield value to a local array.
    arr.push(n)
    //Computes the Collatz-Conjecture
    const sequence = () => {
        //If n is 1 or lower, return
        if (n === 1 || n < 1) {
            return;
        }
        //If n is not straight, multiply it by 3 and add 1
        //Push the result to the local array and rerun
        if (n % 2) {
            n = n * 3 + 1;
            arr.push(n);
            return sequence(n);
        }
        //If n is straight, divide it by 2
        //Push the result to the local array and rerun
        n = n / 2;
        arr.push(n);
        return sequence(n);
    };
    sequence(number);
    return arr;
}

function Chart(arr) {
    const process_data = arr.map(n => [arr.indexOf(n), n])
    return (
        <div style={{
            width: "fit-content",
            minWidth: "195px"
        }}>
            <VictoryChart
                width={195}
                height={165}
                scale={{ x: "time" }}
                containerComponent={<VictoryVoronoiContainer />}>
                <VictoryAxis tickFormat={() => ''} />
                <VictoryLine
                    style={{
                        data: {
                            stroke: config.BG_COLOR,
                            strokeWidth: "1px"
                        }
                    }}
                    data={process_data}
                    x={0}
                    y={1}
                /></VictoryChart>
        </div>
    )
}