export default function Navbar() {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "6vh",
            marginTop: "3vh"
        }}>
            <div style={{
                display: "flex",
                width: "80vw",
                minWidth: "250px",
                justifyContent: "space-between",
                backgroundColor: "#000",
                padding: "10px",
                borderRadius: "10px"
            }}>
                <a style={{
                    textDecoration: "none",
                    color: "lightblue"
                }} href="/">Home</a>
                <a style={{
                    textDecoration: "none",
                    color: "lightblue"
                }} href="collatz">Collatz</a>
                <a style={{
                    textDecoration: "none",
                    color: "lightblue"
                }} href="collatzchampions">Collatz-Champions</a>
            </div>
        </div>
    )
}