import { Component } from "react";
import Navbar from "../components/Navbar";
import { VictoryChart, VictoryLine, VictoryVoronoiContainer, VictoryTooltip } from 'victory'
import config from '../config.json'

/* global BigInt*/

export default class Collatz extends Component {

    state = {
        outval: [],
        length: "",
        max: "",
        zoomDomain: []
    }

    Sequence = this.Sequence.bind(this)
    Chart = this.Chart.bind(this)

    /**
     * Computes the Collatz-Conjunction and stores it in the "outval" array. 
     */
    Sequence() {
        //Retrieves the Value of Inputfield
        var number = document.getElementById("collatzin").value
        let n = number
        let arr = [];
        n = n -1
        n = n +1
        //Pushes the inital Inputfield value to a local array.
        arr.push(n)
        //Computes the Collatz-Conjecture
        const sequence = () => {
            //If n is 1 or lower, return
            if (n === 1 || n < 1) {
                return;
            }
            //If n is not straight, multiply it by 3 and add 1
            //Push the result to the local array and rerun
            if (n % 2) {
                n = n * 3 + 1;
                arr.push(n);
                return sequence(n);
            }
            //If n is straight, divide it by 2
            //Push the result to the local array and rerun
            n = n / 2;
            arr.push(n);
            return sequence(n);
        };
        sequence(number);
        //Removes old values of previous runs from the outval array
        this.setState({ outval: 0 })
        //Pushes the values of the local array to the global outval array
        this.setState({ outval: arr })
        //Pushes the length of the array to the length array
        this.setState({ length: arr.length })
        //Retrieves the largest value in the local array and pushs it to the max array
        let largest = Math.max(...arr)
        this.setState({max: largest})
    }

    /**
     * Generates the Chart with input values from the "outval" array
     */
    Chart() {
        const process_data = this.state.outval.map(n => [this.state.outval.indexOf(n), n])
        const baseLabelStyles = {
            padding: 10,
            fill: "#fff",
            stroke: "transparent"
        };
        console.log(process_data)
        return (
            <div style={{
                width: "50vw",
                minWidth: "320px"
            }}>
                <VictoryChart
                    width={650}
                    height={550}
                    scale={{ x: "time" }}
                    style={{
                        labels: baseLabelStyles
                    }}
                    animate={{ duration: 500 }}
                    containerComponent={<VictoryVoronoiContainer />}>
                    <VictoryLine
                        style={{
                            data: { stroke: config.ACCENT_COLOR },
                            labels: baseLabelStyles
                        }}
                        data={process_data}
                        x={0}
                        y={1}
                        labels={this.state.outval}
                        labelComponent={
                            <VictoryTooltip
                                style={{ fontSize: 10, border: "none" }}
                            />
                        }
                    /></VictoryChart>


            </div>
        )
    }

    render() {
        document.body.style.backgroundColor = config.BG_COLOR
        return (
            <div>
                <Navbar />
                <div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <div style={{
                            marginRight: "5vw",
                            color: "white"
                        }}>
                            Bitte gebe eine Zahl ein.
                            <div style={{
                                marginTop: "2vh"
                            }}>
                                <input style={{
                                    maxHeight: "auto",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    marginBottom: "1vh",
                                    border: "none",
                                    color: "black"
                                }} className="collatzin" id="collatzin" type="number"></input><br />
                                <button style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "lightblue",
                                    border: "none",
                                    cursor: "pointer"
                                }} onClick={this.Sequence}>Berechne die Collatz-Folge</button>
                            </div>
                        </div>
                        <div style={{
                            display: "block"
                        }}>
                            <div style={{
                                color: "white"
                            }}>
                                Die Länge ist:
                                <div style={{
                                    minWidth: "3vw",
                                    width: "fit-content",
                                    maxHeight: "auto",
                                    marginTop: "2vh",
                                    marginBottom: "2vh",
                                    backgroundColor: "gray",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    {this.state.length}
                                </div>
                            </div>
                            <div style={{
                                color: "white"
                            }}>
                                Das Maximum ist:
                                <div style={{
                                    minWidth: "3vw",
                                    width: "fit-content",
                                    maxHeight: "auto",
                                    marginTop: "2vh",
                                    marginBottom: "2vh",
                                    backgroundColor: "gray",
                                    padding: "10px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    {this.state.max}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px"
                    }}>
                        <this.Chart />
                    </div>
                </div>
            </div>
        )
    }
}