import { Component } from "react"
import Navbar from "../components/Navbar"
import config from '../config.json'
import BigInt from 'big-integer'
import Champion from "../components/ChampionList"
var hChampions = []
var LChampions = []
var max_height = 1
var max_length = 1

export default class CollatzCruncher extends Component {

    state = {
        out: [],
        print: [],
        hChamps: [],
        lChamps: [],
        running: false
    }

    runHChampions = this.runHChampions.bind(this)
    runLChampions = this.runLChampions.bind(this)
    HChampion = this.HChampion.bind(this)
    LChampion = this.LChampion.bind(this)
    downloadHChamps = this.downloadHChamps.bind(this)
    downloadLChamps = this.downloadLChamps.bind(this)

    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    _get_length_height(k) {
        let my_height = k
        let my_length = 1n
        while (k > 1n) {
            if ((k % 2n) == 0n) {
                k = k >> 1n
            } else {
                k = k * 3n + 1n
            }
            if (k > my_height) {
                my_height = k
            }
            ++my_length
        }
        return {
            height: my_height,
            length: my_length
        }
    }
    find_extremes_Height(n) {
        var today = new Date();
        var dateStart = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        document.getElementById('resultStart').innerHTML = "[HChampions] Thread started at: " + dateStart + "."
        for (let i = 1n; i <= n; ++i) {
            let extremes = this._get_length_height(i)
            if (extremes.height > max_height) {
                hChampions.push(i)
                max_height = extremes.height
            }
        }
        console.log("Height Champions:")
        console.log(hChampions)

        var todayEnd = new Date();
        var dateEnd = todayEnd.getHours() + ":" + todayEnd.getMinutes() + ":" + todayEnd.getSeconds();
        console.log("Thread started working at: " + dateStart)
        console.log("[HChampions] Thread stopped at: " + dateEnd + ". You may now download the output.")
        document.getElementById('resultEnd').innerHTML = "[HChampions] Thread stopped at: " + dateEnd + ". You may now download the output."
    }

    find_extremes_Length(n) {
        var today = new Date();
        var dateStart = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        document.getElementById('resultStart').innerHTML = "[HChampions] Thread started at: " + dateStart + "."
        for (let i = 1n; i <= n; ++i) {
            let extremes = this._get_length_height(i)
            if (extremes.length > max_length) {
                LChampions.push(i)
                max_length = extremes.length
            }
        }
        console.log("Length Champions:")
        console.log(LChampions)

        var todayEnd = new Date();
        var dateEnd = todayEnd.getHours() + ":" + todayEnd.getMinutes() + ":" + todayEnd.getSeconds();
        console.log("Thread started working at: " + dateStart)
        console.log("[HChampions] Thread stopped at: " + dateEnd + ". You may now download the output.")
        document.getElementById('resultEnd').innerHTML = "[HChampions] Thread stopped at: " + dateEnd + ". You may now download the output."
    }

    /**
         * 
         * @param {BigInt} n The maximum number of the Collatz sequence we want to calculate
         */
    HChampion() {
        var limit = document.getElementById("collatzin").value
        if (limit > 1) {
            let maximum = BigInt(limit)
            this.find_extremes_Height(maximum)
        } else {
            alert("Please use at least 2 or higher!")
        }
    }

    LChampion() {
        var limit = document.getElementById("collatzin").value
        if (limit > 1) {
            let maximum = BigInt(limit)
            this.find_extremes_Length(maximum)

        } else {
            alert("Please use at least 2 or higher!")
        }
    }

    runHChampions() {
        document.getElementById('resultStart').innerHTML = null
        document.getElementById('resultEnd').innerHTML = null
        hChampions = []
        this.HChampion()
    }

    runLChampions() {
        document.getElementById('resultStart').innerHTML = ""
        document.getElementById('resultEnd').innerHTML = ""
        LChampions = []
        this.LChampion()
    }

    downloadHChamps() {
        if (hChampions === undefined || hChampions.length == 0) {
            alert("No results have been saved yet!")
        } else {
            this.download("hchamps.json", hChampions)
        }
    }

    downloadLChamps() {
        if (LChampions === undefined || LChampions.length == 0) {
            alert("No results have been saved yet!")
        } else {
            this.download("lchamps.json", LChampions)
        }
    }

    render() {
        document.body.style.backgroundColor = config.BG_COLOR
        const { out } = this.state;
        return (
            <div>
                <Navbar />
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div style={{
                        width: "80vw",
                        color: "white",
                        display: "block"
                    }}>
                        <div style={{
                            width: "auto",
                            backgroundColor: "#FF5C58",
                            padding: "10px",
                            marginBottom: "30px",
                            border: "2px solid #FC4F4F",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            Beware that a limit of 10.000.000 (about 3min+) or higher can take take a LONG time to calculate.<br />
                            Results can only be downloaded once the thread has stopped!<br />
                            <br />
                            If you get a warning, that the site times out, just let it be like it is. It is still calculating!
                        </div>
                        The max limit to calculate <br />
                        <input style={{
                            maxHeight: "auto",
                            padding: "10px",
                            borderRadius: "10px",
                            marginBottom: "1vh",
                            border: "none",
                            color: "black"
                        }} className="collatzin" id="collatzin" type="number" />
                        <div style={{
                            display: "block"
                        }}>
                            <div style={{
                                width: "auto",
                                border: "2px solid gray",
                                borderRadius: "10px",
                                padding: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "20px",
                                marginTop: "30px",
                                minHeight: "50px"
                            }}>
                                <button id="run-hchamps" style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "lightblue",
                                    border: "none",
                                    cursor: "pointer",
                                    marginRight: "2vw"
                                }} onClick={this.runHChampions}>Calculate the H-Champions</button>
                                <button id="save-hchamps" style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "lightblue",
                                    border: "none",
                                    cursor: "pointer"
                                }} onClick={this.makeArray}>Download the H-Champions</button>
                            </div>
                            <div style={{
                                width: "auto",
                                border: "2px solid gray",
                                borderRadius: "10px",
                                padding: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "20px",
                                marginTop: "30px",
                                minHeight: "50px"
                            }}>
                                <button id="run-lchamps" style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "lightblue",
                                    border: "none",
                                    cursor: "pointer",
                                    marginRight: "2vw"
                                }} onClick={this.runLChampions}>Calculate the L-Champions</button>
                                <button id="save-lchamps" style={{
                                    padding: "10px",
                                    borderRadius: "10px",
                                    backgroundColor: "lightblue",
                                    border: "none",
                                    cursor: "pointer"
                                }} onClick={this.downloadLChamps}>Download the L-Champions</button>
                            </div>
                        </div>
                        <div style={{
                            border: "2px solid gray",
                            borderRadius: "10px",
                            height: "200px",
                            padding: "10px"
                        }}>
                            <h4>Console Output</h4>
                            <div style={{
                            }} id="resultStart"></div>
                            <div style={{
                            }} id="resultEnd"></div>
                        </div>
                        <div style={{
                            marginTop: "30px"
                        }}>
                            <h4>The current max calculated Number is 100.000.000!</h4>
                            <div style={{
                                color: "white"
                            }}>
                                <Champion/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
