import './App.css';
import Navbar from './components/Navbar';
import config from './config.json'
const val = document.getElementsByClassName('collatzin').value

/**
 * @author Paul Fischerländer
 * In January of 2022
 * @returns 
 */

export default function App() {
  document.body.style.backgroundColor = config.BG_COLOR
  return (
    <div className="App" style={{
      display: "block"
    }}>
      <Navbar/>
    </div>
  );
}



